import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Styles
import styles from './CallerInformation.module.css';

// Utils
import { ctxValue } from '../../utils/config';
const subdomain = ctxValue('SUBDOMAIN');

const CallerInformation = (props) => {
  const history = useHistory();
  const [ agentClaimData, setAgentClaimData ] = useState();
  const [ ipInfo, setIpInfo ] = useState();
  const [ authenticatedBy, setAuthenticatedBy ] = useState();

  useEffect(() => {
    setAgentClaimData(props.location.state.call?.callerData?.agentClaimData);
    setIpInfo(props.location.state.ipInfo);

    if ( ! props.location.state?.authFactors ) {
      console.warn(`useEffect() setAuthenticatedBy:undefined - 'authFactors' not in props`);
      setAuthenticatedBy(undefined);
      return;
    }

    const authFactors = props.location.state.authFactors;
    if ( Array.isArray(authFactors) && authFactors.length>0 ) {
      const lastAuthFactorKey = authFactors.slice(-1)[0];
      const authenticatedBy = 
        { 'ac'      : 'Authentication Cookie'
        , 'psml'    : 'Preenroll Magic Link'          // only allowed on the first authentication request
        , 'pvc'     : 'Previous Verification Cookie'
        , 'smsotp'  : 'SMS OTP'
        , 'passkey' : 'Passkey'
        }[lastAuthFactorKey] || undefined;
      setAuthenticatedBy(authenticatedBy);
      if (!authenticatedBy) console.warn(`useEffect() setAuthenticatedBy:undefined - unknown authFactorKey:${lastAuthFactorKey}`);
    }
  }, [props.location.state]);

  const handleCompleteButton = () => {
    history.push({ pathname: '/caller-queue', hash: subdomain });
  };

  const renderField = (field, index) => {
    return <div key={index}>
        <div className={styles.fieldLabel}>{ field.label }</div>
        <div className={styles.fieldValue}>{ field.value }</div>
      </div>
  };

  const renderAgentClaimData = (acd) => {
    if (!acd) return <></>;
    return <> { acd.map((field, index) => renderField(field, index)) } </>
  };

  return (
    <>
      <div className='row'>
        <div className='mx-auto' style={{ width: '280px' }}>
          <div className={styles.gridContainer}>
            <div className={styles.authImageContainer}>
              <img src={`${process.env.PUBLIC_URL}/icons/ic_check_mark_grn_lg.png`} alt='Authenticated' className={styles.authImage}></img>
            </div>
            <div className={styles.authHeader}>
              Authenticated
            </div>
            { authenticatedBy &&
              <div className={styles.authBy}>
                <span className={styles.authLabel}>by</span><b>{authenticatedBy}</b>
              </div>
            }
            <div className={styles.authLocation}><span className={styles.authLabel}>location</span>
              { (ipInfo?.isRelay==='false') ? <>{ipInfo.city}, {ipInfo.regionName}</> : <>not available</> }
            </div>
          </div>

          { renderAgentClaimData(agentClaimData) }

          <div className='text-center' style={{ marginBottom: '40px', marginTop: '20px' }}>
            <button className='btn btn-primary btn-lg shadow-none' onClick={() => handleCompleteButton()} >
              OK
            </button>
          </div>

        </div>
      </div>
    </>
  );
};

export default CallerInformation;