import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ctxValue } from './config';
import { revision } from './revision';
import 
{ getAccessToken
, setAccessToken
, getRefreshToken
, setRefreshToken
, setUser
} from '../utils/session';

const subdomain = ctxValue('SUBDOMAIN');
const identity_svc = ctxValue('IDENTITY_SERVICE');
const user_auth_svc = ctxValue('USER_AUTH_SERVICE');
const unknownErrorMsg = 'Unknown error';

const _request403Retry = async (method, url, body) => {
  let retried=false;
  async function run() {
    const {token} = await getAccessToken();
    const axiosMethod = {get:axios.get, post:axios.post, delete:axios.delete}[method];
    const params = (method==='post') ? [url, body] : [url];
    let config = (method==='delete' && body) 
      ? { headers: {'Authorization': `Bearer ${token}`, 'x-client':`IDgo Agent ${revision}`}, data: body }
      : { headers: {'Authorization': `Bearer ${token}`, 'x-client':`IDgo Agent ${revision}`} }
      ;
    return axiosMethod(...params, config)
      .catch(err => {
        if (err?.response?.status===403) {
          if (retried) {
            alert(`Sorry, your session has expried and you need to Sign In again.`)
            window.location.replace(`/#${subdomain}`);
            return {...err.response, status:403, message: err?.response?.data?.message || err?.message || unknownErrorMsg};
          }
          retried=true;
          return _refreshAccessToken().then(run);
        }
        if (err.response?.status===404) {
          return {...err.response, status:404, message: err?.response?.data?.message || err?.message || unknownErrorMsg};
        }
        return {...err.response, status:500, message: err?.response?.data?.message || err?.message || unknownErrorMsg};
      });
  }
  return run();
};

const _refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    console.info('services._refreshAccessToken() no refreshToken');
    return;
  }
  try {
    const decodedToken = jwtDecode(refreshToken);
    const result = await axios.get(
      `${user_auth_svc}/v1/auth/refresh/${decodedToken?.agentId}`,
      { headers: {'Authorization': `Bearer ${refreshToken}`} }
    );
    setAccessToken('cozera', result.data.accessToken);
    setRefreshToken(result.data.refreshToken);
    setUser(result.data.user);
  } catch (err) {
    console.error('services._refreshAccessToken() error: %o', err);
  }
};

const enrollUser = async (mobileNumber, createdBy, data) => {
  const body = { mobileNumber, createdBy, data };
  return _request403Retry('post', `${identity_svc}/relyingParty/autoEnroll/${subdomain}/v1`, body);
};

const authenticateUser = async ({coziId, authContext, mobileNumber, realm, callerIdNomatchChecked, agentName}) => {
  const body =
  { coziId
  , verificationRequestType: 'on-demand'
  , authContext
  , phone: mobileNumber
  , realm
  , notificationType: 'sms'
  , data: { userVerifyDialedNumber: callerIdNomatchChecked, agentName }
  };
  return _request403Retry('post', `${identity_svc}/verifyUser/ondemand/v1`, body);
};

const getAccountStatus = async (mobileNumber) => {
  return _request403Retry('get', `${identity_svc}/account/v1/${mobileNumber}/${subdomain}`);
};

const getProofVerificationActivity = async ({mobileNumber, startDatetime, endDatetime}) => {
  let url = `${identity_svc}/event/proofVerification/v1/${subdomain}/${mobileNumber}`;
  if (startDatetime) {
    url += `?startDatetime=${startDatetime}`;
    if (endDatetime) {
      url += `&endDatetime=${endDatetime}`;
    }
  }
  return _request403Retry('get', url);
};

// Given a mobile number and subdomain, revoke all relying party owned claims.
const revokeEnrollment = async ({mobileNumber, revokeReason}) => {
  const body = { revokeReason };
  return _request403Retry('post', `${identity_svc}/relyingParty/revokeEnrollment/v1/${subdomain}/${mobileNumber}`, body);
};

// Given a coziId, delete an IDgo account
const deleteAccount = async ({coziId}) => {
  return _request403Retry('delete', `${identity_svc}/account/v1/${coziId}`);
};

// Notify all other IDgo Agents apps that you have taken this call
const updateCallStatusByCallID = async ({callId, operation, subdomain}) => {
  const body = { callId, operation, subdomain };
  return _request403Retry('post', `${identity_svc}/callQueue/updateStatusByCallID`, body);
};

// this catch block error messages from the server (data.message), network errors (err.message), or static general message
const updateResetPassword = async (agentId, newPassword, oneTimeToken) => {
  const body = { newPassword };
  const url = `${user_auth_svc}/v1/users/reset/password/${agentId}/${oneTimeToken}`;
  return axios.post(url, body)
    .catch(err => { return {status:500, message: err?.response?.data?.message || err?.message || unknownErrorMsg}});
};
// this catch block error messages from the server (data.message), network errors (err.message), or static general message
const forgotPassword = async (email) => {
  const body = { email };
  const url = `${user_auth_svc}/v1/users/reset/password/${subdomain}`;
  return axios.post(url, body)
  .catch(err => { return {status:500, message: err?.response?.data?.message || err?.message || unknownErrorMsg}});
};

const changePassword = async (oldPassword, newPassword) => {
  const body = { oldPassword, newPassword };
  return _request403Retry('post', `${user_auth_svc}/v1/users/update/password`, body);
};

const changeEmail = async (newEmail) => {
  const body = { email: newEmail };
  return _request403Retry('post', `${user_auth_svc}/v1/users/update/email`, body);
};

const getRelyingParty = async () => {
  return _request403Retry('get', `${identity_svc}/relyingParty/v1/${subdomain}`);
};

const getVerificationResult = async (verificationRequestId) => {
  return _request403Retry('get', `${identity_svc}/verifyUser/result/${verificationRequestId}/${subdomain}`);
};

// this is legacy/backward compatible logic to be removed after presentVerificationResult() is rolled out
const getAccountData = async (mobileNumber) => {
  return _request403Retry('get', `${identity_svc}/account/data/${mobileNumber}/${subdomain}`);
};

const login = async (email, password) => {
  const result = await axios.post( `${user_auth_svc}/v1/auth`, { email, password, subdomain }, { headers: { 'Content-Type': 'application/json' } })
    .catch(err => {
      if (err?.response?.status===429) {
        return {status:500, message: 'Account locked out, please wait 5 minutes before trying again.'};
      }
      const remainingAttempts = err?.response?.headers['x-ratelimit-remaining'];
      if (remainingAttempts && remainingAttempts === '0') {
        return {status:500, message: 'Too many failed attempts, please try again after 5 minutes.'};
      }
      return {status:500, message: err?.response?.data?.message || err?.message || unknownErrorMsg}
    });

  if (result.status===200) {
    setAccessToken('cozera', result.data.accessToken);
    setRefreshToken(result.data.refreshToken);
    setUser(result.data.user);
  }
  return result;
};

/**
 * This logout() function used to delete the AccessToken, RefreshToken, and User information from local storage.
 * If an enterprise uses both long lived IDgo Agent windows and short lived (popup or iFrames),
 * The long-lived windows will auto sign-out and delete this information causing short-lived windows having to re-sign in.
 */
const logout = () => {
  setAccessToken(undefined);
  setRefreshToken(undefined);
  setUser(undefined);
};

export
{ enrollUser
, authenticateUser
, getAccountStatus
, getProofVerificationActivity  
, revokeEnrollment  
, deleteAccount
, updateCallStatusByCallID
, updateResetPassword
, forgotPassword
, changePassword
, changeEmail
, getRelyingParty
, getVerificationResult
, getAccountData
, login
, logout
}